<template>
  <Form :model="form" style="padding-left: 80px; padding-right: 80px;">
    <FormItem label="标题">
      <Input v-model="form.title" placeholder="请输入标题" />
    </FormItem>
    <FormItem label="图片">
      <div v-if="uploadedImages.length === 0">暂未上传图片</div>
      <div v-else>
        <draggable class="uploadedImages" element="ul" :list="uploadedImages" :options="{ group: 'title', animation: 150 }"
          :no-transition-on-drag="true">
          <span class="imgWrapper" v-for="(i, index) in uploadedImages" :key="i.path">
            <img v-real-img="i.url" style="width: 80px; height: 80px" />
            <span class="imgFrist" v-if="index === 0">首图</span>
            <Button class="imgClose" shape="circle" icon="md-close" @click="removeUploadImage(i.path)"></Button>
          </span>
        </draggable>
      </div>
    </FormItem>
    <FormItem label="">
        <Upload multiple :headers="{ 'X-Auth': token }" action="/backstage/media/uploadImages" name="images"
          :format="['jpg', 'jpeg', 'png']" :max-size="5120" :before-upload="filesReadyToUpload"
          :on-exceeded-size="exceededSize"
          :on-success="filesUploaded" :show-upload-list="false">
          <Button icon="ios-cloud-upload-outline">上传</Button>
        </Upload>
      </FormItem>
    <FormItem label="正文" label-position="top">
    </FormItem>
    <quill-editor v-model="form.content" ref="myQuillEditor"></quill-editor>
    <FormItem label="作者">
      <Button @click="showUserModel">{{ userInfo.name ? userInfo.name : "选择用户" }}</Button>
    </FormItem>
    <FormItem label="店铺">
      <Button @click="showStoreModel">{{ storeInfo.name ? storeInfo.name : "选择店铺" }}</Button>
    </FormItem>
    <Modal v-model="showUserSelector" width="80%" title="选择用户">
      <UserList @selectionChanged="userSelectionChanged" v-if="showUserSelector" />
      <div slot="footer">
        <Button @click="showUserSelector = false">确定</Button>
      </div>
    </Modal>
    <Modal v-model="showStoreList" title="选择店铺" width="80%">
      <StoreList @selectionChanged="storeSelectionChanged" v-if="showStoreList" ref="shop" />
    </Modal>
  </Form>
</template>
    
<script>
import "quill/dist/quill.core.css"
import "quill/dist/quill.snow.css"
import "quill/dist/quill.bubble.css"
import { getArticleDetail } from "@/api"
import UserList from "@/components/user/UserList"
import StoreList from "@/components/store/StoreList"
import draggable from "vuedraggable"
import { quillEditor } from "vue-quill-editor"

const maxFiles = 9

export default {
  name: "ArticeDetail",
  components: { draggable, UserList, quillEditor, StoreList },
  props: {
    alias: {
      type: String,
      default: "",
    }
  },
  data() {
    return {
      uploadedImages: [],
      token: sessionStorage.getItem("token"),
      form: {
        title: "",
        content: "",
        userAlias: "",
        storeAlias: ""
      },
      userInfo: {},
      storeInfo: {},
      showUserSelector: false,
      showStoreList: false
    }
  },
  computed: {},
  async mounted() {
    await this.reload()
  },
  watch: {
    alias() {
      this.reload()
    },
    "userInfo.alias"(alias) {
      this.form.userAlias = alias
    },
    "storeInfo.storeAlias"(alias) {
      this.form.storeAlias = alias
    }
  },
  methods: {
    async reload() {
      try {
        if (this.alias) {
          const form = await getArticleDetail(this.alias)
          this.form = form
          this.form.alias = form.articleAlias
          this.userInfo.alias = form.authorAlias
          this.userInfo.name = form.authorName
          this.form.userAlias = form.authorAlias
          this.storeInfo = form.store
          this.form.storeAlias = form.store.storeAlias
          this.uploadedImages = form.medias
        } else {
          this.form = {}
          this.userInfo = {}
          this.storeInfo = {}
          this.uploadedImages = []
        }
      } catch (e) {
        this.$Message.error(e.message)
      }
    },
    filesReadyToUpload() {
      if (1 + this.uploadedImages.length > maxFiles) {
        this.$Message.error(`单个商品最多可以上传${maxFiles}张图片`)
        return false
      }
    },
    filesUploaded(response) {
      if (this.uploadedImages.length + 1 > maxFiles) {
        this.$Message.error(`单个商品最多可以上传${maxFiles}张图片, 多余的图片将被忽略`)
        return
      }
      this.uploadedImages = this.uploadedImages.concat(response.data)
    },
    removeUploadImage(key) {
      this.uploadedImages = this.uploadedImages.filter(image => image.path != key)
    },
    showUserModel() {
      this.showUserSelector = true
    },
    userSelectionChanged(e) {
      this.userInfo = e
      this.showUserSelector = false
      console.log(e)
    },
    showStoreModel() {
      this.showStoreList = true
    },
    storeSelectionChanged(e) {
      this.storeInfo = e
      this.showStoreList = false
      console.log(e)
    },
    exceededSize() {
      this.$Message.error("图片大小超过5m")
    }
  },
}
</script>
    
<style>

</style>
    